import InputField from "@/components/forms/formComponents/InputField/InputField";
import SelectClient from "@/components/selects/SelectClient/SelectClient";
import SelectSessionType, {
  SELECT_SESSION_TYPE_TOOLTIP
} from "@/components/selects/SelectSessionType/SelectSessionType";
import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission";
import { emptyArr } from "@/utilities/empties";

import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

const REQUIRED_FIELDS = ["client"];

export default function SessionForm({
  form,
  submit,
  title,
  submitButtonLabel,
  loading,
  submitButton,
  subTitle,
  hiddenFields = emptyArr,
  requiredFields = REQUIRED_FIELDS,
  hideNewClientButton,
  disableStartSessionButton = false
}) {
  const { t } = useTranslation();
  const hasPermission = useLazyHasPermission();
  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = form;

  return (
    <form
      data-theme="insoles-light"
      onSubmit={handleSubmit(submit)}
      className="SessionForm flex flex-col gap-5"
    >
      <h1 className="text-2xl">{title}</h1>

      {subTitle && <h3 className="text-sm">{subTitle}</h3>}

      {hasPermission("clientCreate") && !hideNewClientButton && (
        <div className="flex">
          <div className="flex-1"></div>
          <Link
            to={location.pathname.replace("/new_session", "/new_client")}
            className="btn btn-primary btn-xs"
          >
            {t("New Client")}
          </Link>
        </div>
      )}

      {!hiddenFields.includes("client") && (
        <InputField label={t("Client")} error={errors["client"]}>
          <SelectClient
            control={control}
            rules={{ required: requiredFields.includes("client") }}
          />
        </InputField>
      )}

      {!hiddenFields.includes("sessionType") && (
        <InputField
          label={t("Session Type")}
          error={errors["sessionType"]}
          tooltip={t(SELECT_SESSION_TYPE_TOOLTIP)}
        >
          <SelectSessionType
            control={control}
            rules={{ required: requiredFields.includes("sessionType") }}
            creatable={(newData) => setValue("sessionType", newData)}
          />
        </InputField>
      )}

      {!hiddenFields.includes("notes") && (
        <InputField label={t("Notes")} error={errors["notes"]}>
          <textarea
            {...register("notes")}
            className="textarea textarea-bordered resize w-full"
          ></textarea>
        </InputField>
      )}

      {!hiddenFields.includes("isTestSession") && (
        <InputField
          className="!flex-row justify-center gap-5"
          label={t("Test Session?")}
          error={errors["isTestSession"]}
        >
          <input
            {...register("isTestSession")}
            data-tip={t("This session will be deleted after recording")}
            type="checkbox"
            className="toggle toggle-error tooltip tooltip-left"
          />
        </InputField>
      )}

      <div className="flex justify-end">
        <button
          type="button"
          className="btn btn-outline btn-sm mr-5 px-8"
          onClick={() => navigate(-1, { replace: true })}
          disabled={loading}
        >
          {t("Cancel")}
        </button>

        {submitButton ? (
          submitButton
        ) : (
          <button
            type="submit"
            disabled={loading || disableStartSessionButton}
            className={`btn btn-success btn-sm px-8 `}
          >
            {loading ? (
              <span className="loading loading-ring loading-md text-neutral"></span>
            ) : (
              submitButtonLabel
            )}
          </button>
        )}
      </div>
    </form>
  );
}
