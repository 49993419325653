import "@/configurations/sentry/sentry";
import useInitializeApolloClient from "@/configurations/apollo/useInitializeApolloClient";
import { ApolloProvider } from "@apollo/client";
import { StrictMode } from "react";
import { RouterProvider } from "react-router-dom";
import SplashScreen from "../SplashScreen/SplashScreen";
import usePrepareForWeb from "@/hooks/tauri/usePrepareForWeb";
import useInitializeLanguage from "@/hooks/useInitializeLanguage";
import { ResetApolloContext } from "@/configurations/apollo/resetApolloContext";
import { persistor } from "@/configurations/store";
import { PersistGate } from "redux-persist/integration/react";
import router from "../Router/router";
import { Toaster } from "react-hot-toast";

export default function App() {
  usePrepareForWeb();
  useInitializeLanguage();
  const { client, reset, key } = useInitializeApolloClient();

  if (!client) return <SplashScreen />;

  return (
    <>
      <Toaster toastOptions={{ duration: 2000 }} />

      <ApolloProvider client={client} key={key}>
        <StrictMode>
          <ResetApolloContext.Provider value={reset}>
            <PersistGate loading={null} persistor={persistor}>
              <RouterProvider router={router} />
            </PersistGate>
          </ResetApolloContext.Provider>
        </StrictMode>
      </ApolloProvider>
    </>
  );
}
