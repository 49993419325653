import { gql } from "@apollo/client";
import SUBSCRIPTION_FRAGMENT from "./subscriptionFragment";
import SETTINGS_FRAGMENT from "../settings/settingsFragment";

const FACILITY_FRAGMENT = gql`
  fragment FacilityFragment on Facility {
    id
    name
    shareAllClients
    RMA
    subscription {
      ...SubscriptionFragment
    }
    desktopSettings {
      ...SettingsFragment
    }
  }

  ${SUBSCRIPTION_FRAGMENT}
  ${SETTINGS_FRAGMENT}
`;

export default FACILITY_FRAGMENT;
