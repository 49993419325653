import { invoke } from "@tauri-apps/api/tauri";
import { useCallback, useState } from "react";
import useOpenDesktopApp from "../useOpenDesktopApp/useOpenDesktopApp";
import { useSelector } from "react-redux";
import { DATA_PATH } from "@/utilities/paths/paths";
import useCheckIsOnline from "@/hooks/account/useCheckIsOnline";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { writeTextFile, BaseDirectory } from "@tauri-apps/api/fs";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { DEFAULT_SETTINGS_SESSION_ID } from "@/utilities/constants";
import useDownloadDesktopSettings from "@/hooks/io/useDownloadDesktopSettings/useDownloadDesktopSettings";
import useDownloadDefaultSettingsXsn from "@/hooks/io/useDownloadDefaultSettingsXsn/useDownloadDefaultSettingsXsn";
import { FILE_EXTENSION } from "@/constants";
import { error, info } from "@/utilities/log";

export default function useOpenDefaultSettings() {
  const [loading, setLoading] = useState(false);
  const openDesktopApp = useOpenDesktopApp();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const checkIsOnline = useCheckIsOnline();
  const downloadDesktopSettings = useDownloadDesktopSettings();
  const downloadDefaultXsn = useDownloadDefaultSettingsXsn();
  const openDefaultSettings = useCallback(
    async (chainProcess) => {
      const sessionID = DEFAULT_SETTINGS_SESSION_ID;
      info("[useOpenDefaultSettings] started ", sessionID);
      setLoading(true);
      const isOnline = await checkIsOnline();

      if (!isOnline || !isLoggedIn) return;

      try {
        await downloadDefaultXsn(sessionID);

        const chain = await openDesktopApp(
          chainProcess,
          downloadDesktopSettings
        );

        info("[useOpenDefaultSettings] getSessionPaths ", sessionID);

        const { metaPath } = await getSessionPaths({
          sessionID,
          extension: FILE_EXTENSION.XSN,
        });

        const localMeta = {
          sessionID,
          extension: FILE_EXTENSION.XSN,
        };

        info("[useOpenDefaultSettings] writeTextFile ", sessionID);

        await writeTextFile(metaPath, JSON.stringify(localMeta), {
          dir: BaseDirectory.App,
        });

        info("[useOpenDefaultSettings] invoke open_session ", sessionID);

        await invoke("open_session", {
          sessionId: sessionID,
          path: DATA_PATH.SETTINGS_DEFAULT,
        });

        info("[useOpenDefaultSettings] completed ", sessionID);

        return chain;
      } catch (e) {
        error("[useOpenDefaultSettings] error", e);

        throw e;
      } finally {
        setLoading(false);
      }
    },
    [
      checkIsOnline,
      isLoggedIn,
      downloadDefaultXsn,
      openDesktopApp,
      downloadDesktopSettings,
    ]
  );

  return [openDefaultSettings, { loading }];
}
