import { CONVERTED_LANGUAGE } from "@/components/selects/SelectLanguage/constants";
import i18n from "@/configurations/i18n";
import { locale } from "@tauri-apps/api/os";
import { info } from "@/utilities/log";
import { useEffect } from "react";

export default function useInitializeLanguage() {
  useEffect(() => {
    const fn = async () => {
      const theLocale = await locale();
      const language = CONVERTED_LANGUAGE[theLocale];

      info("[change language] ", language);
      i18n.changeLanguage(language);
    };

    fn();
  }, []);
}
