import { useForm } from "react-hook-form";
import useCompleteChangePassword from "./hooks/useCompleteChangePassword";
import FormInput from "@/components/forms/formComponents/FormInput";
import { checkMatchingFields } from "@/utilities/validators";
import { useTranslation } from "react-i18next";
import PasswordRequirements from "@/components/PasswordRequirements/PasswordRequirements";
import checkCognitoPasswordRequirements from "@/utilities/validators/checkCognitoPasswordRequirements";

export default function CompleteChangePasswordForm() {
  const { t } = useTranslation();
  const form = useForm();
  const {
    register,
    handleSubmit,
    getValues,
    watch,
    formState: { errors },
  } = form;
  const { password } = watch();
  const { completeChangePassword, loading } =
    useCompleteChangePassword(getValues);

  return (
    <form
      className="flex flex-col"
      onSubmit={handleSubmit(completeChangePassword)}
    >
      <h3 className="mb-8 text-center text-3xl font-bold">
        {t("Reset Password")}
      </h3>

      <FormInput
        name="password"
        label={t("New Password")}
        errors={errors}
        placeholder={t("Password")}
        type="password"
        className="pb-0"
        {...register("password", {
          required: t("Password is required"),
          validate: checkCognitoPasswordRequirements,
        })}
      />

      <FormInput
        label={t("Confirm Password")}
        errors={errors}
        name="confirmPassword"
        type="password"
        placeholder={t("Confirm Password")}
        className="flex-1"
        {...register("confirmPassword", {
          validate: checkMatchingFields(password, t("Passwords do not match")),
        })}
      />

      <PasswordRequirements className="mb-5" />

      <button disabled={loading} className={`btn-success btn `}>
        {loading ? (
          <span className="loading loading-ring loading-md text-neutral"></span>
        ) : (
          t("Reset Password")
        )}
      </button>
    </form>
  );
}
