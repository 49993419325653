import USER_CREATE from "@/api/user/userCreate"
import { useLazyHasPermission } from "@/hooks/account/useHasPermission/useHasPermission"
import { useMutation } from "@apollo/client"
import { useContext, useMemo } from "react"
import { useForm } from "react-hook-form"
import UserForm from "../UserForm"
import { useTranslation } from "react-i18next"
import { ROLE } from "@/views/UserList/constants"
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility"
import useSubmit from "./useSubmit"
import { RootContext } from "@/context/rootContext"
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser"

export default function NewUserForm() {
  const hasPermission = useLazyHasPermission()
  const { t } = useTranslation()
  const { userManyQuery } = useContext(RootContext)
  const [_, { refetch }] = userManyQuery
  const myFacility = useSelectCurrentFacility()
  const shareAllClients = myFacility?.shareAllClients
  const form = useForm()
  const { getValues, reset, watch } = form
  const formValues = watch()
  const [createUser, { loading }] = useMutation(USER_CREATE)
  const submit = useSubmit({ createUser, refetch, getValues, reset })
  const currentUser = useCurrentUser();
  const isXsensorAdmin = useMemo(() => (
    currentUser.currentRole?.name === ROLE.XSENSOR_ADMIN || formValues?.role?.value === ROLE.XSENSOR_ADMIN
  ), [currentUser.currentRole?.name, formValues?.role?.value]);
  const hiddenFields = useMemo(
    () => [
      !hasPermission("spoofFacility") && "facility",
      (shareAllClients || isXsensorAdmin) &&
        "clients"
    ],
    [hasPermission, shareAllClients, isXsensorAdmin]
  )

  return (
    <UserForm
      form={form}
      submit={submit}
      loading={loading}
      isXsensorAdmin={isXsensorAdmin}
      hiddenFields={hiddenFields}
      title={t("Create New User")}
      submitLabel={t("Create")}
    />
  )
}
