import { useCallback, useContext } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import useSyncDocument from "@/hooks/io/useSyncDocument";
import { BaseDirectory, removeFile } from "@tauri-apps/api/fs";
import { RootContext } from "@/context/rootContext";
import { error, info } from "@/utilities/log";

export default function useSyncSessionReportExport() {
  const { t } = useTranslation();
  const syncDocument = useSyncDocument();
  const { sessionManyQuery } = useContext(RootContext);
  const [_, { refetch }] = sessionManyQuery;
  const syncSessionReportExport = useCallback(
    async (metaData, fileName) => {
      info("[useSyncSessionReportExport] started ", metaData);
      const { sessionIDs } = metaData;

      try {
        const { exportsPath } = await getSessionPaths({ fileName });

        await syncDocument(exportsPath, metaData, false);
        await removeFile(exportsPath, { dir: BaseDirectory.App });
        await refetch?.();

        toaster.success(t("Session Report Export successfully synced"));

        info("[useSyncSessionReportExport] completed ", sessionIDs);
      } catch (e) {
        error("Failed to sync session report export", e);
        toaster.error(t("Failed to Sync Session Report Export"));

        throw new Error(e);
      }
    },
    [t, syncDocument, refetch]
  );

  return syncSessionReportExport;
}
