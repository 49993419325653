import useSelectUser from "@/hooks/apolloState/useSelectUser";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import UserForm from "../UserForm";
import { ROLE } from "@/views/UserList/constants";
import { useTranslation } from "react-i18next";
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility";
import useSelectFacility from "@/hooks/apolloState/useSelectFacility";
import useSubmit from "./useSubmit";
import { useQuery } from "@apollo/client";
import MY_USER from "@/api/user/myUser";

export default function EditUserForm() {
  const { t } = useTranslation();
  const { userID } = useParams();
  const user = useSelectUser(userID);
  const myFacility = useSelectCurrentFacility();
  const shareAllClients = myFacility?.shareAllClients;
  const facility = useSelectFacility(user?.facility);
  const form = useForm({
    defaultValues: async () => {
      return {
        ...user,
        role: { value: user.role.name, label: user.role.name },
        facility,
      };
    },
  });
  const hideRoles = useMemo(
    () => (user.role.name !== ROLE.XSENSOR_ADMIN ? [ROLE.XSENSOR_ADMIN] : []),
    [user.role.name]
  );
  const { getValues, watch } = form;
  const { role } = watch();
  const [editUser, { loading }] = useSubmit(getValues, userID);
  const { data } = useQuery(MY_USER);
  const currentUser = data?.myUser;
  const hiddenFields = useMemo(
    () => [
      (shareAllClients ||
        currentUser.currentRole?.name === ROLE.XSENSOR_ADMIN ||
        role?.value === ROLE.XSENSOR_ADMIN) &&
        "clients",
    ],
    [currentUser.currentRole?.name, role?.value, shareAllClients]
  );

  const disabledFields = useMemo(
    () => [
      "email",
      (currentUser.currentRole?.name !== ROLE.XSENSOR_ADMIN ||
        currentUser.id === user.id) &&
        "facility",
    ],
    [currentUser, user]
  );

  return (
    <UserForm
      hiddenFields={hiddenFields}
      disabledFields={disabledFields}
      submit={editUser}
      form={form}
      loading={loading}
      hideRoles={hideRoles}
      title={t("Edit User")}
      submitLabel={t("Update")}
    />
  );
}
