import { useCallback } from "react";
import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { REGION_OPTIONS } from "./constants";
import { relaunch } from "@tauri-apps/api/process";
import useWriteLocalConfig from "@/hooks/io/useLocalConfigFile/useWriteLocalConfig";
import usePlatform from "@/hooks/tauri/usePlatform";

export default function SelectRegion({ form, ...props }) {
  const { isWeb } = usePlatform();
  const writeLocalConfig = useWriteLocalConfig();
  const { t } = useTranslation();
  const onChange = useCallback(
    async (e) => {
      const region = REGION_OPTIONS.find(({ value }) => value === e.value);

      await writeLocalConfig({ region: region?.value });

      isWeb ? location.reload() : await relaunch();

      form.setValue("region", region);
    },
    [writeLocalConfig, form, isWeb]
  );

  return (
    <Controller
      control={form.control}
      name="region"
      render={({ field }) => {
        return (
          <SuperSelect
            placeholder={t("Region")}
            options={REGION_OPTIONS}
            classNames={{
              container: () => "w-[160px]",
            }}
            {...field}
            {...props}
            onChange={onChange}
          />
        );
      }}
    />
  );
}
