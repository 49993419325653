import * as Sentry from "@sentry/react";
import { invoke } from "@tauri-apps/api";

export const identifyUser = (user: {
  id: string;
  email?: string;
  username?: string;
}) => {
  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: user.username,
  });

  Sentry.startSession();
};

export async function clearUserSession(isWeb) {
  Sentry.endSession();
  Sentry.setUser(null);

  if (!isWeb) {
    await invoke("end_sentry_session");
  }
}
