import { appConfigDir, join } from "@tauri-apps/api/path";
import { createDir, exists, writeBinaryFile } from "@tauri-apps/api/fs";
import { DATA_PATH } from "@/utilities/paths/paths";
import { useCallback } from "react";
import { error, info } from "@/utilities/log";
import useDownloadFile from "../useDownloadFile/useDownloadFile";
import { fetch } from "@tauri-apps/api/http";

export default function useDownloadDefaultSettingsXsn() {
  const download = useDownloadFile();
  const downloadDefaultSettingsXsn = useCallback(
    async (sessionID) => {
      info("[useDownloadDefaultSettingsXsn] started ", sessionID);

      try {
        const defaultPath = await join(
          await appConfigDir(),
          DATA_PATH.SETTINGS_DEFAULT
        );
        const defaultFilePath = await join(defaultPath, `${sessionID}.xsn`);

        if (await exists(defaultFilePath)) {
          info("[useDownloadDefaultSettingsXsn] found default xsn ", sessionID);
          return;
        }

        createDir(defaultPath, { recursive: true });

        const preSignedUrl = await download("default");

        const response = await fetch(preSignedUrl?.data?.getDownloadUrl, {
          headers: {
            "Content-Type": "application/octet-stream",
          },
        });
        const fileBlob = await response.blob();

        const fileContent = await fileBlob.arrayBuffer();

        await writeBinaryFile({ path: defaultFilePath, contents: fileContent });

        info("[useDownloadDefaultSettingsXsn] completed ", sessionID);
      } catch (e) {
        error("[useDownloadDefaultSettingsXsn] error ", e);
      }
    },
    [download]
  );

  return downloadDefaultSettingsXsn;
}
