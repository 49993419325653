import USER from "@/api/user/userFragment";
import { useApolloClient } from "@apollo/client";

export default function useSelectUser(userID) {
  const client = useApolloClient();
  const reference = client.cache.identify({
    __typename: "User",
    id: userID,
  });
  const user = client.readFragment({
    id: reference,
    fragmentName: "UserFragment",
    fragment: USER,
  });

  return user;
}
