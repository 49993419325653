import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import FacilityForm from "../FacilityForm";
import FACILITY_CREATE from "@/api/facility/facilityCreate";
import { useNavigate } from "react-router-dom";
import { toaster } from "@/utilities/toaster";
import { error } from "@/utilities/log";

export default function CreateFacilityForm() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useForm({ defaultValues: { date: new Date() } });
  const { getValues } = form;
  const [createFacility, { loading }] = useMutation(FACILITY_CREATE);
  const submit = useCallback(async () => {
    try {
      await createFacility({
        variables: {
          name: getValues("name"),
          subscriptionExpirationDate: getValues("date")?.toISOString(),
          RMA: getValues("RMA"),
        },
      });

      toaster.success(t("Facility Created"));

      navigate("../");
    } catch (e) {
      error(e);
      toaster.error(t("There was a problem creating the facility"));
    }
  }, [createFacility, getValues, navigate, t]);

  return (
    <FacilityForm
      form={form}
      submit={submit}
      loading={loading}
      hiddenFields={["share"]}
      title={t("Create New Facility")}
      submitLabel={t("Create")}
      className="h-full"
    />
  );
}
