import { error, info } from "@/utilities/log";
import { BaseDirectory, readTextFile } from "@tauri-apps/api/fs";
import { useCallback } from "react";
import { LocalConfig } from "./types";
import { LOCAL_CONFIG_PATH } from "./useWriteLocalConfig";
import usePlatform from "@/hooks/tauri/usePlatform";
import { useSelector } from "react-redux";
import { selectWebConfig } from "@/redux/environment/selectors";

export default function useReadLocalConfig() {
  const { isWeb } = usePlatform();
  const webConfig = useSelector(selectWebConfig);
  const readLocalConfig = useCallback(async (): Promise<LocalConfig | null> => {
    info("[readLocalConfig] started");
    try {
      if (isWeb) return webConfig;

      const configText = await readTextFile(LOCAL_CONFIG_PATH, {
        dir: BaseDirectory.App,
      });
      const config = JSON.parse(configText) as LocalConfig;

      info("[readLocalConfig] completed ", config);

      return config;
    } catch (e) {
      error("[readLocalConfig] error", e);

      return webConfig;
    }
  }, [isWeb, webConfig]);

  return readLocalConfig;
}
