import { setContext } from "@apollo/client/link/context";
import { fetchAuthSession } from "aws-amplify/auth";

const authLink = setContext((_, { headers }) => {
  return fetchAuthSession().then((authSession) => {
    const tokens = authSession?.tokens;

    if (!tokens) {
      return {
        headers,
      };
    }

    return {
      headers: {
        ...headers,
        Authorization: tokens.accessToken,
        "authorization-id": tokens.idToken,
      },
    };
  });
});

export default authLink;
