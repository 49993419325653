import i18n from "@/configurations/i18n";
import { toaster } from "@/utilities/toaster";
import { error } from "@/utilities/log";
import { GraphQLError } from "graphql";

export default function handleGraphqlErrors(graphqlError: GraphQLError) {
  const { message, path } = graphqlError;

  if (message.includes("E11000")) {
    toaster.error(`${i18n.t("That already exists")} (${path})`);
    return;
  } else if (message.includes("User account already exists")) {
    toaster.error(i18n.t("This email address is already in use"));
    return;
  } else if (message.includes("Authentication required")) {
    return;
  }

  toaster.error(message);
  error("Error link (graphql): ", message);
}
