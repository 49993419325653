import SESSION_INSERT from "@/api/session/sessionInsert";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { toaster } from "@/utilities/toaster";
import { SESSION_VERSION } from "@/components/forms/SessionForm/constants";
import useMoveFile from "./useMoveFile/useMoveFile";
import { useTranslation } from "react-i18next";
import useSyncToS3 from "./useSyncToS3";
import { FILE_EXTENSION } from "@/constants";
import { error, info } from "@/utilities/log";

export default function useSyncDocument() {
  const { t } = useTranslation();
  const syncToS3 = useSyncToS3();
  const moveFile = useMoveFile();
  const [sessionInsert] = useMutation(SESSION_INSERT);
  const syncDocument = useCallback(
    async (filePath, meta, shouldToast) => {
      info("[useSyncDocument] started ", meta, " ", filePath);
      const { isTestSession } = meta;

      if (isTestSession) {
        return Promise.resolve();
      }

      const { activePath, offlinePath } = await getSessionPaths({
        sessionID: meta.sessionID,
        extension: meta?.extension || FILE_EXTENSION.XSN,
      });

      try {
        // sync the document file first
        await syncToS3(filePath);
        // sync meta data after document is synced
        await sessionInsert({
          variables: {
            ...meta,
            version: SESSION_VERSION,
          },
        });

        shouldToast && toaster.success(t("Session successfully synced"));

        info("[useSyncDocument] completed ", meta.sessionID);
      } catch (e) {
        error("Failed to sync xsn", e);
        shouldToast && toaster.error(t("Failed to Sync XSN"));
        moveFile(activePath, offlinePath);

        throw new Error(e);
      }
    },
    [syncToS3, sessionInsert, moveFile, t]
  );

  return syncDocument;
}
