import { gql } from "@apollo/client";
import PAGE_INFO from "../pageInfo/pageInfoFragment";
import SESSION_FRAGMENT from "./sessionFragment";

const SESSION_MANY = gql`
  query SESSION_MANY(
    $clientID: ID!
    $sort: JSON
    $filter: SessionFilterInput
    $limit: Int
    $offset: Int
  ) {
    sessionMany(
      clientID: $clientID
      sort: $sort
      filter: $filter
      limit: $limit
      offset: $offset
    ) {
      payload {
        ...SessionFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }

  ${SESSION_FRAGMENT}
  ${PAGE_INFO}
`;

export default SESSION_MANY;
