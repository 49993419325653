import { info } from "@/utilities/log";
import { BaseDirectory, writeTextFile } from "@tauri-apps/api/fs";
import { useCallback } from "react";
import { LocalConfig } from "./types";
import useReadLocalConfig from "./useReadLocalConfig";
import usePlatform from "@/hooks/tauri/usePlatform";
import { useDispatch, useSelector } from "react-redux";
import { selectWebConfig } from "@/redux/environment/selectors";
import { setWebConfig } from "@/redux/environment/slice";

export const LOCAL_CONFIG_PATH = "user-local-config.json";

export default function useWriteLocalConfig() {
  const dispatch = useDispatch();
  const { isWeb } = usePlatform();
  const readLocalConfig = useReadLocalConfig();
  const oldWebConfig = useSelector(selectWebConfig);
  const writeLocalConfig = useCallback(
    async (config: LocalConfig) => {
      info("[writeLocalConfig] started ", config);
      try {
        if (isWeb) {
          dispatch(setWebConfig({ ...oldWebConfig, ...config }));
          return;
        }
        const oldConfig = await readLocalConfig();
        const newConfig = !oldConfig ? config : { ...oldConfig, ...config };

        await writeTextFile(LOCAL_CONFIG_PATH, JSON.stringify(newConfig), {
          dir: BaseDirectory.App,
        });

        info("[writeLocalConfig] completed");
      } catch (e) {
        info("[writeLocalConfig] ", e);
      }
    },
    [oldWebConfig, isWeb, readLocalConfig, dispatch]
  );

  return writeLocalConfig;
}
