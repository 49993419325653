import { useQuery } from "@apollo/client"
import { useMemo } from "react"
import SuperSelect from "../SuperSelect/SuperSelect"
import { Controller } from "react-hook-form"
import USER_MANY from "@/api/user/userMany"
import { useTranslation } from "react-i18next"

export default function SelectUsers({ roles, control, rules, ...props }) {
  const { t } = useTranslation()
  const { data, previousData, loading } = useQuery(USER_MANY, {
    fetchPolicy: "network-only",
    variables: {
      filter: { roles },
      limit: 1000
    }
  })
  const users = data?.userMany?.payload || previousData?.userMany?.payload
  const sorted = useMemo(() => {
    const clone = users ? [...users] : []

    clone?.sort((a, b) => a.name.localeCompare(b.name))

    return clone
  }, [users])

  return (
    <Controller
      control={control}
      name="users"
      rules={rules}
      render={({ field }) => {
        return (
          <SuperSelect
            className="text-base-100"
            classNamePrefix="super-select"
            placeholder={t("Clinicians")}
            options={sorted}
            getOptionLabel={(option) => {
              const isObject = typeof option === "object"

              return isObject
                ? option?.name
                : users?.find((user) => user.id === option)?.name
            }}
            getOptionValue={(option) => {
              const isObject = typeof option === "object"

              return isObject ? option?.id : option
            }}
            isLoading={loading}
            closeMenuOnSelect={false}
            isMulti
            {...field}
            {...props}
          />
        )
      }}
    />
  )
}
