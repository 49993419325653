import { signIn, signOut } from "aws-amplify/auth";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import useHandleLoginErrors from "./useHandleLoginErrors";
import useCompleteLogin from "./useCompleteLogin";
import { User } from "@xsensor/intelligent-insoles-types";
import { invoke } from "@tauri-apps/api";
import useGlobalSignOut from "@/hooks/account/useGlobalSignOut";
import useInitializeEnvironment from "@/hooks/environment/useInitializeEnvironment";
import usePlatform from "@/hooks/tauri/usePlatform";
import * as Sentry from "@sentry/react";

export default function useLogin(getValues: (key: string) => any) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const globalSignOut = useGlobalSignOut();
  const handleLoginErrors = useHandleLoginErrors();
  const completeLogin = useCompleteLogin();
  const initializeEnvironment = useInitializeEnvironment();
  const { isWeb } = usePlatform();

  const login = useCallback(
    async (user: User) => {
      setLoading(true);

      try {
        const environment = await initializeEnvironment();
        const email = (getValues ? getValues("email") : user?.username)?.trim();
        const password = getValues && getValues("password");
        // this is to ensure only one session is active at a time
        await globalSignOut(email, environment);
        await signOut();
        const { nextStep } = await signIn({ username: email, password });
        const sentryEnv = environment?.STAGE + "-" + environment?.REGION;
        const platformSuffix = import.meta.env.TAURI_PLATFORM ? "" : "-web";
        const fullSentryEnv = sentryEnv + platformSuffix;

        if (
          nextStep?.signInStep === "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED"
        ) {
          navigate("/complete_change_password", {
            state: {
              username: email,
              password: password,
            },
          });

          return;
        }

        Sentry.startSession({
          user: { email },
          environment: fullSentryEnv,
          release: "intelligent-insoles@" + import.meta.env.APP_VERSION,
        });
        Sentry.addEventProcessor((event) => {
          event.environment = fullSentryEnv;
          return event;
        });

        if (!isWeb) {
          await invoke("start_sentry_session", {
            email,
            environment: fullSentryEnv,
          });
        }

        await completeLogin();
      } catch (e) {
        handleLoginErrors(e, getValues);
      }

      setLoading(false);
    },
    [
      getValues,
      globalSignOut,
      navigate,
      handleLoginErrors,
      completeLogin,
      initializeEnvironment,
      isWeb,
    ]
  );

  return { login, loading };
}
