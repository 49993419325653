import useDownloadAndSave from "@/hooks/io/useDownloadAndSave/useDownloadAndSave";
import toExportExtension from "@/utilities/text/toExportExtension";
import { toaster } from "@/utilities/toaster";
import { save } from "@tauri-apps/api/dialog";
import { downloadDir, join } from "@tauri-apps/api/path";
import { error } from "@/utilities/log";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export default function useDownloadReport({
  session,
  startProgress,
  stopProgress,
}) {
  const { t } = useTranslation();
  const { sessionID, exportType } = session;
  const [opening, setOpening] = useState(false);
  const downloadAndSave = useDownloadAndSave();
  const downloadReport = useCallback(async () => {
    try {
      const downloadPath = await downloadDir();
      const fileExtension = toExportExtension(exportType);
      const defaultPath = await join(
        downloadPath,
        `${sessionID}.${fileExtension}`
      );
      const filePath = await save({
        defaultPath,
        directory: true,
        multiple: false,
        title: "Choose the directory to save",
        filters: [
          {
            name: `.${fileExtension}`,
            extensions: [fileExtension],
          },
        ],
      });

      await downloadAndSave(filePath);

      startProgress(sessionID);

      setOpening(true);

      stopProgress(sessionID);

      setOpening(false);
    } catch (e) {
      setOpening(false);
      stop(sessionID);

      // this is the error message that is thrown when the user cancels the download
      if (e === "invalid type: null, expected a string") return;

      toaster.error(t("Failed to download session"));
      error("[useDownloadReport]", e);
    }
  }, [sessionID, exportType, downloadAndSave, startProgress, stopProgress, t]);

  return [downloadReport, { opening }];
}
