import { Environment } from "@xsensor/intelligent-insoles-types";
import { ResourcesConfig } from "aws-amplify";

export default function getAmplifyConfig(
  environment: Environment
): ResourcesConfig {
  return {
    Auth: {
      Cognito: {
        userPoolId: environment.COGNITO_USER_POOL_ID,
        userPoolClientId: environment.COGNITO_CLIENT_ID,
        signUpVerificationMethod: "code",
        loginWith: {
          email: true,
          phone: false,
          username: false,
        },
      },
    },
  };
}
