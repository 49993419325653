import { error } from "@/utilities/log";
import { checkUpdate } from "@tauri-apps/api/updater";
import { useCallback } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom/dist";

export default function useHandleLoginErrors() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleLoginErrors = useCallback(
    (e: Error, getValues) => {
      error(e);
      if (e?.code === "UserNotConfirmedException") {
        toaster.error(t("Please enter verification code"));
        navigate("/verify", {
          state: {
            username: getValues("email"),
            password: getValues("password"),
          },
        });
      } else if (e?.code === "CLIENT_OUT_OF_DATE") {
        toaster.error(t("Please update your app to the latest version"));
        import.meta.env.PROD && checkUpdate();
      } else if (e?.code === "NEW_PASSWORD_REQUIRED") {
        navigate("/reset_password");
      } else if (e?.message === "Failed to fetch") {
        toaster.error(t("Check your internet connection..."), {
          id: "checkConnection",
        });
      } else {
        toaster.error(t("Error logging in, Please try again"));
      }
    },
    [t, navigate]
  );

  return handleLoginErrors;
}
