import { useTranslation } from "react-i18next";

export default function PasswordRequirements({
  className,
}: {
  className?: string;
}) {
  const { t } = useTranslation();

  return (
    <div className={className}>
      <h6>{t("Password Must Contain:")}</h6>
      <ul className="list-disc pl-4 text-xs">
        <li>{t("A Minimum of 8 characters but less than 99 characters")}</li>
        <li>{t("At least one number (0-9)")}</li>
        <li>{t("At least one lowercase letter (a-z)")}</li>
        <li>{t("At least one uppercase letter (A-Z)")}</li>
        <li>
          {`${t(
            "At least one special character:"
          )}  ^ $ * . [ ] { } ( ) ? " ! @ # % & / \\ , > < ' : ; | _ ~ \``}
        </li>
      </ul>
    </div>
  );
}
