import { ApolloLink, HttpLink } from "@apollo/client";
import getApiUrl from "@/utilities/getApiUrl";

export const dynamicHttpLink = new ApolloLink((operation, forward) => {
  const url = getApiUrl()
  const httpLink = new HttpLink({
    uri: url + "/graphql",
  });

  return httpLink.request(operation, forward);
});
