import { useApolloClient, useQuery } from "@apollo/client";
import FACILITY_FRAGMENT from "@/api/facility/facilityFragment";
import MY_USER from "@/api/user/myUser";

export default function useSelectCurrentFacility() {
  const { data } = useQuery(MY_USER);
  const currentUser = data?.myUser;
  const client = useApolloClient();
  const reference = client.cache.identify({
    __typename: "Facility",
    id: currentUser?.facility,
  });
  const facility = client.readFragment({
    id: reference,
    fragmentName: "FacilityFragment",
    fragment: FACILITY_FRAGMENT,
  });

  return facility;
}
