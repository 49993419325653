import { toaster } from "@/utilities/toaster";
import { getCurrentUser, updatePassword } from "aws-amplify/auth";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export default function useResetPassword(reset) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const resetPassword = useCallback(
    async (formValues) => {
      try {
        setLoading(true);
        await updatePassword({
          oldPassword: formValues.currentPassword,
          newPassword: formValues.newPassword,
        });

        await getCurrentUser();

        reset();
        toaster.success(t("Password has been reset"));

        setLoading(false);
      } catch (e) {
        setLoading(false);

        throw e;
      }
    },
    [reset, t]
  );

  return { resetPassword, loading };
}
