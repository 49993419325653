import { resetPassword } from "aws-amplify/auth";
import { useCallback, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { error } from "@/utilities/log";
import useInitializeAmplify from "@/hooks/useInitializeAmplify";
import useInitializeEnvironment from "@/hooks/environment/useInitializeEnvironment";

export default function useRequestPasswordReset() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const initializeEnvironment = useInitializeEnvironment();
  const initializeAmplify = useInitializeAmplify();
  const sendResetPasswordCode = useCallback(
    async (formData) => {
      const { email } = formData;

      try {
        const environment = await initializeEnvironment();
        await initializeAmplify(environment);
        setLoading(true);
        await resetPassword({ username: email });

        navigate("/reset_password_code");
        toaster.success(t("Check email for confirmation code"));
      } catch (e) {
        toaster.error(t("Error sending reset password code"));
        error(e.message);
      } finally {
        setLoading(false);
      }
    },
    [initializeEnvironment, initializeAmplify, navigate, t]
  );

  return { sendResetPasswordCode, loading };
}
