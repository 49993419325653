import { error } from "@/utilities/log";
import { confirmSignIn } from "aws-amplify/auth";
import { useCallback, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import useCompleteLogin from "../../LoginForm/useCompleteLogin";

export default function useCompleteChangePassword(getValues) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const completeLogin = useCompleteLogin();
  const completeChangePassword = useCallback(async () => {
    try {
      const { password } = getValues();
      setLoading(true);

      const { isSignedIn, nextStep } = await confirmSignIn({
        challengeResponse: password,
      });

      toaster.success(t("Account activated"));
      setLoading(false);

      await completeLogin();
    } catch (e) {
      error(e);
      toaster.error(t("Error Changing Password"));
    } finally {
      setLoading(false);
    }
  }, [t, getValues, completeLogin]);

  return { completeChangePassword, loading };
}
