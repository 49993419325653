import { useCallback } from "react";
import { Amplify } from "aws-amplify";
import { PURGE } from "@/redux/purge";
import { store } from "@/configurations/store";
import { Environment } from "@xsensor/intelligent-insoles-types";
import { info } from "@/utilities/log";
import getAmplifyConfig from "@/configurations/getAmplifyConfig";

export default function useInitializeAmplify() {
  const initalizeAmplify = useCallback(async (environment: Environment) => {
    store.dispatch(PURGE());
    const amplifyConfig = getAmplifyConfig(environment);

    info("[useInitializeAmplify] config: ", amplifyConfig);

    Amplify.configure(amplifyConfig);
  }, []);

  return initalizeAmplify;
}
