import SYNC_URL from "@/api/sync/syncUrl";
import { useLazyQuery } from "@apollo/client";
import { readBinaryFile } from "@tauri-apps/api/fs";
import { BaseDirectory } from "@tauri-apps/api/path";
import { useCallback } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { path } from "@tauri-apps/api";
import { Body, fetch } from "@tauri-apps/api/http";
import { error, info } from "@/utilities/log";

export default function useSyncToS3() {
  const { t } = useTranslation();
  const [syncUrl] = useLazyQuery(SYNC_URL);
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const syncXsn = useCallback(
    async (filePath: string) => {
      info(`[useSyncToS3] started `, filePath);

      try {
        const fileName = await path.basename(filePath);
        // ------------ get the presigned upload url ------------
        const { data } = await syncUrl({
          variables: {
            fileName,
          },
        });

        const xsn = await readBinaryFile(filePath, {
          dir: BaseDirectory.App,
        });

        if (!data?.syncUrl) throw new Error(t("No sync url received"));

        // sync the xsn to the s3 bucket
        await fetch(data?.syncUrl, {
          method: "PUT",
          headers: {
            ContentType: "application/octet-stream",
          },
          body: Body.bytes(xsn),
        });

        info("[useSyncToS3] completed ", filePath);
      } catch (e) {
        isLoggedIn &&
          toaster.error(
            t("Failed to sync session, please check the offline page to retry")
          );
        error(`[useSyncToS3] error`, e);

        throw new Error(e);
      }
    },
    [syncUrl, isLoggedIn, t]
  );

  return syncXsn;
}
