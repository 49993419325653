import { gql } from "@apollo/client";
import USER_FRAGMENT from "./userFragment";

const USER_DELETE = gql`
  mutation USER_DELETE(
    $id: ID!
  ) {
    userDelete(
      id: $id
    ) {
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export default USER_DELETE;
