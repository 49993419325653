import { createSlice } from "@reduxjs/toolkit";
import { Region } from "@xsensor/shared-types";

const initialState = {
  environment: {},
  platform: import.meta.env.TAURI_PLATFORM,
  webConfig: {
    region: Region.ca_central_1,
  },
};

export const environmentSlice = createSlice({
  name: "ENVIRONMENT",
  initialState,
  reducers: {
    setEnvironment: (state, { payload }) => {
      state.environment = payload;
    },
    setPlatform: (state, { payload }) => {
      state.platform = payload;
    },
    setWebConfig: (state, { payload }) => {
      state.webConfig = payload;
    },
  },
  // do not purge environment
  // extraReducers: () => ({
  //   ...purge(initialState),
  // }),
});

export const { setEnvironment, setPlatform, setWebConfig } =
  environmentSlice.actions;

export default environmentSlice;
