import { getMetaPath } from "@/utilities/paths/getSessionPaths";
import { listen } from "@tauri-apps/api/event";
import { BaseDirectory, removeFile } from "@tauri-apps/api/fs";
import { useEffect } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useLazyOfflineMeta } from "../io/useOfflineMeta";
import { error, info } from "@/utilities/log";

const ERROR = {
  NO_SENSORS: "no_sensors",
};

export default function useListenForErrorEvent() {
  const { t } = useTranslation();
  const [refreshOfflineMeta] = useLazyOfflineMeta();

  useEffect(() => {
    const unlisten = listen("error", async (event) => {
      info("[useListenForErrorEvent] ", event);

      if (event?.payload?.error === ERROR.NO_SENSORS) {
        toaster.error(t("Could not create session, no sensors connected."));
        const metaPath = await getMetaPath(event?.payload?.sessionID);

        // cleanup the meta file
        try {
          await removeFile(metaPath, { dir: BaseDirectory.App });
          await refreshOfflineMeta();
        } catch (e) {
          error(
            "[useListenForErrorEvent] error ",
            event?.payload?.sessionID + " : " + e
          );
        }
      }
    });

    return () => {
      unlisten.then((f) => f());
    };
  }, [t, refreshOfflineMeta]);
}
