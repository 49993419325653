import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LANGUAGE_OPTIONS, LANGUAGE, CONVERTED_LANGUAGE } from "./constants";
import { useDispatch } from "react-redux";
import { setManuallySelectedLanguage } from "@/redux/account/slice";
import { info } from "@/utilities/log";

export default function SelectLocalLanguage({ ...props }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const converted = CONVERTED_LANGUAGE[i18n.language];
  const { control, setValue } = useForm({
    defaultValues: {
      language: { value: converted, label: LANGUAGE[converted] },
    },
  });

  return (
    <Controller
      control={control}
      name="language"
      render={({ field }) => {
        return (
          <SuperSelect
            placeholder={t("Language")}
            options={LANGUAGE_OPTIONS}
            {...field}
            {...props}
            onChange={(e) => {
              const converted = CONVERTED_LANGUAGE[e.value];
              const language = {
                value: converted,
                label: LANGUAGE[converted],
              };

              dispatch(setManuallySelectedLanguage(e.value));

              info("[change language] ", e.value);
              i18n.changeLanguage(e.value);

              setValue("language", language);
            }}
          />
        );
      }}
    />
  );
}
