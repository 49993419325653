import { useCallback } from "react";
import getApiUrl from "@/utilities/getApiUrl";
import { info } from "@/utilities/log";

export default function useGetClientEnvVars() {
  const url = getApiUrl();
  const getClientEnvVars = useCallback(async () => {
    const response = await fetch(url + "/client_env_vars", {
      method: "GET",
      headers: {
        // "Apollographql-Client-Version": import.meta.env.APP_VERSION,
        "Content-Type": "application/json",
      },
    });
    const envVars = await response.json();

    info("Client env vars", envVars);

    return envVars;
  }, [url]);

  return getClientEnvVars;
}
