import { useCallback } from "react";
import { useForm } from "react-hook-form";
import checkMatchingFields from "@/utilities/validators/checkMatchingFields";
import PasswordRequirements from "@/components/PasswordRequirements/PasswordRequirements";
import useResetPassword from "./hooks/useResetPassword";
import FormInput from "@/components/forms/formComponents/FormInput";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import checkCognitoPasswordRequirements from "@/utilities/validators/checkCognitoPasswordRequirements";
import { toaster } from "@/utilities/toaster";
import { error } from "@/utilities/log";

export default function ResetPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const form = useForm({
    defaultValues: { newPassword: "", currentPassword: "" },
  });
  const { register, handleSubmit, getValues, formState, watch, reset } = form;
  const formValues = watch();
  const { errors } = formState;
  const { resetPassword, loading } = useResetPassword(reset);
  const submit = useCallback(async () => {
    try {
      await resetPassword(getValues());
      navigate("../");
    } catch (e) {
      toaster.error(t("Password Reset Failed, Please Try Again"));
      error(e);
    }
  }, [resetPassword, getValues, navigate, t]);

  return (
    <form
      className="flex w-full min-w-[200px] flex-col gap-5"
      onSubmit={handleSubmit(submit)}
    >
      <h3 className="text-center text-3xl font-bold">{t("Reset Password")}</h3>

      <FormInput
        name="currentPassword"
        label={t("Current Password")}
        errors={errors}
        placeholder={t("Current Password")}
        type="password"
        {...register("currentPassword", {
          required: t("Current password is required"),
        })}
      />

      <div className="flex gap-4">
        <FormInput
          name="newPassword"
          label={t("New Password")}
          errors={errors}
          placeholder={t("New Password")}
          type="password"
          className="flex-1"
          {...register("newPassword", {
            required: t("New password is required"),
            validate: checkCognitoPasswordRequirements,
          })}
        />

        <FormInput
          name="confirmPassowrd"
          label={t("Confirm Password")}
          errors={errors}
          placeholder={t("Confirm Password")}
          type="password"
          className="flex-1"
          {...register("confirmPassowrd", {
            required: t("New password is required"),
            validate: checkMatchingFields(
              formValues.newPassword,
              t("Passwords do not match")
            ),
          })}
        />
      </div>

      <PasswordRequirements />

      <div className="flex-grow"></div>

      <div className="flex justify-end gap-4">
        <Link to="../" className="btn btn-neutral btn-sm btn-outline px-6">
          {t("Cancel")}
        </Link>

        <button
          className={`btn-success btn-sm btn px-6 ${loading && "btn-disabled"}`}
          disabled={loading}
          type="submit"
        >
          {loading ? (
            <span className="loading loading-ring loading-md text-neutral"></span>
          ) : (
            t("Save")
          )}
        </button>
      </div>
    </form>
  );
}
