import { format } from "date-fns";
import { invoke } from "@tauri-apps/api/tauri";
import { useCallback, useState } from "react";
import useOpenDesktopApp from "../useOpenDesktopApp/useOpenDesktopApp";
import {
  addOpenSession,
  addSessionMeta,
  removeOpeningSession,
  setOpeningSession,
} from "@/redux/sessions/slice";
import { useDispatch, useSelector } from "react-redux";
import useMoveFile from "../../io/useMoveFile/useMoveFile";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import { DATA_PATH } from "@/utilities/paths/paths";
import useSetSessionLock from "../useSetSessionLock";
import useCheckIsOnline from "@/hooks/account/useCheckIsOnline";
import { selectIsLoggedIn } from "@/redux/account/selectors";
import { FILE_EXTENSION } from "@/constants";
import { error, info } from "@/utilities/log";

export default function useOpenSession() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const openDesktopApp = useOpenDesktopApp();
  const moveFile = useMoveFile();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const setSessionLock = useSetSessionLock();
  const checkIsOnline = useCheckIsOnline();
  const openSession = useCallback(
    async (session, chainProcess, downloadDesktopSettings = undefined) => {
      info("[useOpenSession] started ", session);
      setLoading(true);
      dispatch(setOpeningSession(session.sessionID));
      const isOnline = await checkIsOnline();

      try {
        isOnline && isLoggedIn && (await setSessionLock(session, true));
        const { offlinePath, activePath } = await getSessionPaths({
          sessionID: session?.sessionID,
          extension: FILE_EXTENSION.XSN,
        });
        const chain = await openDesktopApp(
          chainProcess,
          downloadDesktopSettings
        );

        if (session?.offline) {
          await moveFile(offlinePath, activePath);
        }

        await invoke("open_session", {
          sessionId: session?.sessionID,
          sessionType: session?.sessionType,
          sessionDate: session?.createdAt ? format(new Date(session.createdAt), "PP") : undefined,
          clientId: session?.clientID,
          clientName: session?.fullname,
          path: DATA_PATH.ACTIVE,
        });

        dispatch(addSessionMeta(session));
        dispatch(addOpenSession(session.sessionID));

        info("[useOpenSession] completed ", session);

        dispatch(removeOpeningSession(session.sessionID));
        setLoading(false);

        return chain;
      } catch (e) {
        setLoading(false);
        dispatch(removeOpeningSession(session.sessionID));
        error("[useOpenSession] error", e);

        isOnline && (await setSessionLock(session, false));

        throw e;
      }
    },
    [
      openDesktopApp,
      dispatch,
      moveFile,
      setSessionLock,
      checkIsOnline,
      isLoggedIn,
    ]
  );

  return [openSession, { loading }];
}
