import { setIsOnline } from "@/redux/account/slice";
import getApiUrl from "@/utilities/getApiUrl";
import { useCallback } from "react";
import { useDispatch } from "react-redux";

export default function useCheckIsOnline() {
  const dispatch = useDispatch();
  const apiUrl = getApiUrl();
  const checkIsOnline = useCallback(async () => {
    try {
      if (!apiUrl) return;

      await fetch(apiUrl + "/is_online", {
        headers: {
          "Apollographql-Client-Version": import.meta.env.APP_VERSION,
        },
      });

      dispatch(setIsOnline(true));
      return true;
    } catch (e) {
      dispatch(setIsOnline(false));
      return false;
    }
  }, [dispatch, apiUrl]);

  return checkIsOnline;
}
