import { useCallback } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { BaseDirectory, writeTextFile } from "@tauri-apps/api/fs";
import useMoveFile from "@/hooks/io/useMoveFile/useMoveFile";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import useOfflineMeta from "@/hooks/io/useOfflineMeta";
import { error, info } from "@/utilities/log";

export default function useSaveOfflineSessionReportExport() {
  const { t } = useTranslation();
  const moveFile = useMoveFile();
  const { refetch } = useOfflineMeta();
  const saveOfflineSessionReportExport = useCallback(
    async (metaData, fileName) => {
      info("[useSaveOfflineSessionReportExport] started ", metaData);
      const { sessionID } = metaData;

      try {
        const { exportsPath, offlinePath, metaPath } = await getSessionPaths({
          fileName,
          sessionID,
        });

        await writeTextFile(
          metaPath,
          JSON.stringify({ ...metaData, offline: true }),
          {
            dir: BaseDirectory.App,
          }
        );

        await moveFile(exportsPath, offlinePath);

        await refetch();
        toaster.success(t("Session export saved offline"));

        info("[useSaveOfflineSessionReportExport] completed ", fileName);
      } catch (e) {
        error("Failed to sync file", e);
        toaster.error(t("Failed to Sync file"));

        throw new Error(e);
      }
    },
    [t, moveFile, refetch]
  );

  return saveOfflineSessionReportExport;
}
