import { createSelector } from "@reduxjs/toolkit";
import { Region } from "@xsensor/shared-types";

export const selectEnvironment = (state) => state.environment.environment;
export const selectPlatform = (state) => state.environment.platform;
export const selectWebConfig = (state) => state.environment.webConfig;
export const selectRegion = createSelector(
  [selectEnvironment],
  (environment) => {
    return environment.REGION as Region;
  }
);
