import toast, { ToastOptions } from "react-hot-toast";

const dismissableToast =
  (toastFn) => (message: string, options?: ToastOptions) => {
    const toastId = toastFn(message, options);

    setTimeout(() => {
      toast.dismiss(toastId);
    }, 2000);
  };

export const toaster = {
  defaultToast: dismissableToast(toast),
  success: dismissableToast(toast.success),
  error: dismissableToast(toast.error),
};
