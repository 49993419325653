import { useMemo } from "react"
import useCurrentUser from "@/hooks/account/useCurrentUser/useCurrentUser"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import useResendInviteOrResetPassword from "../hooks/useResendInviteOrResetPassword"

export default function Actions({ user }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentUser = useCurrentUser()
  const [resendInviteOrResetPassword, { loading }] = useResendInviteOrResetPassword(user)
  const isDisabled = !user.enabled
  const passwordButtonLabel = useMemo(() => !user.isEmailVerified ? t("Resend  Invite") : t("Reset Password"), [
    t,
    user.isEmailVerified,
  ]);

  return (
    <div className="flex justify-center">
      <button
        disabled={isDisabled}
        className="text-decoration-none btn-ghost btn-xs btn text-success disabled:text-primary-content"
        onClick={() => navigate(`edit/${user.id}`)}
      >
        {t("Edit")}
      </button>

      <div className="divider divider-horizontal m-0"></div>

      <button
        disabled={isDisabled ?? loading}
        className="text-decoration-none btn-ghost btn-xs btn text-success w-[100px]"
        onClick={resendInviteOrResetPassword}
      >
        {passwordButtonLabel}
      </button>

      <div className="divider divider-horizontal m-0"></div>

      <Link
        className={`text-decoration-none btn-ghost btn-xs btn ${
          isDisabled ? "text-success" : "text-red-500"
        } ${
          currentUser?.id === user.id &&
          "btn-disabled btn-active opacity-40"
        }`}
        to={
          isDisabled
            ? `enable_user/${user.id}`
            : `disable_user/${user.id}`
        }
      >
        {isDisabled ? t("Enable") : t("Disable")}
      </Link>
    </div>
  )
}
