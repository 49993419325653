import MY_USER from "@/api/user/myUser";
import { selectCurrentUser } from "@/redux/account/selectors";
import { useLazyQuery } from "@apollo/client";
import { error } from "@/utilities/log";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "@/redux/account/slice";

export default function useCurrentUser() {
  const currentUser = useSelector(selectCurrentUser);

  return currentUser;
}

export function useLazyCurrentUser() {
  const dispatch = useDispatch();
  const [getMyUser] = useLazyQuery(MY_USER);
  const getCurrentUser = useCallback(async () => {
    try {
      const response = await getMyUser();

      dispatch(setCurrentUser(response?.data?.myUser));

      return response?.data?.myUser;
    } catch (e) {
      error(e);
    }
  }, [getMyUser, dispatch]);

  return getCurrentUser;
}
