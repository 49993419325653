import { useCallback } from "react";
import { copyFile, removeFile } from "@tauri-apps/api/fs";
import { basename, join } from "@tauri-apps/api/path";
import { DATA_PATH } from "@/utilities/paths/paths";
import { error } from "@/utilities/log";

export default function useLazyMoveSettings() {
  const moveSettings = useCallback(async (settings, appConfigPath) => {
    return Promise.all(
      settings?.map(async ({ file: fromPath, setting }) => {
        try {
          const fileName = await basename(fromPath);
          const toPath = await join(
            appConfigPath,
            DATA_PATH.SETTINGS_ACTIVE,
            fileName
          );

          if (fromPath !== toPath) {
            await copyFile(fromPath, toPath);
            await removeFile(fromPath);
          }

          return { file: fromPath, setting, toPath };
        } catch (e) {
          error("[useLazyMoveSettings] error ", e);
        }
      })
    );
  }, []);

  return moveSettings;
}
