import { error } from "@/utilities/log";
import { getCurrentUser } from "aws-amplify/auth";
import { useCallback, useEffect } from "react";

// refresh token every 30 minutes
const REFRESH_TOKEN_INTERVAL = 1000 * 60 * 30;

export default function useRefreshToken() {
  const refreshToken = useCallback(async () => {
    try {
      await getCurrentUser();
    } catch (e) {
      error(e);
    }
  }, []);

  useEffect(() => {
    const timeout = setInterval(refreshToken, REFRESH_TOKEN_INTERVAL);

    return () => clearInterval(timeout);
  }, [refreshToken]);
}
