import USER_UPDATE from "@/api/user/userUpdate";
import i18n from "@/configurations/i18n";
import useCurrentUser, {
  useLazyCurrentUser,
} from "@/hooks/account/useCurrentUser/useCurrentUser";
import { toaster } from "@/utilities/toaster";
import { ROLE } from "@/views/UserList/constants";
import { useApolloClient, useMutation } from "@apollo/client";
import { error, info } from "@/utilities/log";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useUpdateSettings(getValues) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const client = useApolloClient();
  const [userUpdate] = useMutation(USER_UPDATE);
  const [loading, setLoading] = useState(false);
  const currentUser = useCurrentUser();
  const getCurrentUser = useLazyCurrentUser();
  const updateSettings = useCallback(async () => {
    const language = getValues("language")?.value;

    setLoading(true);

    try {
      await userUpdate({
        variables: {
          id: currentUser?.id,
          language,
          currentRole:
            currentUser?.role?.name === ROLE.XSENSOR_ADMIN
              ? ROLE.XSENSOR_ADMIN
              : getValues("currentRole")
              ? ROLE.ADMIN
              : ROLE.STANDARD,
        },
      });
      // refresh the current user in apollo cache
      await getCurrentUser();

      await client.resetStore();
      info("[change language] ", language);
      i18n.changeLanguage(language);

      navigate("../");
      toaster.success(t("Settings updated"));
    } catch (e) {
      error(e);
      toaster.error(t("Error updating settings"));
    } finally {
      setLoading(false);
    }
  }, [client, getValues, userUpdate, t, navigate, getCurrentUser, currentUser]);

  return [updateSettings, { loading }];
}
