import MY_USER from "@/api/user/myUser";
import useAutoReconcile from "@/hooks/io/useAutoReconcile";
import useCleanupActiveSessions from "@/hooks/io/useCleanupActiveSessions";
import useUpdateAllMeta from "@/hooks/io/useUpdateAllMeta";
import { useLazyQuery } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useGlobalSignOut from "@/hooks/account/useGlobalSignOut";
import { useDispatch } from "react-redux";
import { setCurrentUser, setIsLoggedIn } from "@/redux/account/slice";
import useHandleLoginErrors from "./useHandleLoginErrors";
import useCleanupBadMetaData from "@/hooks/io/useCleanupBadMetaData";
import useUpdateUserLanguage from "./useUpdateUserLanguage";
import { identifyUser } from "@/configurations/sentry/identifyUser";

export default function useCompleteLogin() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getMyUser] = useLazyQuery(MY_USER);
  const cleanupActiveSessions = useCleanupActiveSessions();
  const updateAllMeta = useUpdateAllMeta();
  const autoReconcile = useAutoReconcile();
  const globalSignOut = useGlobalSignOut();
  const cleanupBadMetaData = useCleanupBadMetaData();
  const updateUserLanguage = useUpdateUserLanguage();
  const handleLoginErrors = useHandleLoginErrors();
  const completeLogin = useCallback(async () => {
    const result = await getMyUser();
    const myUser = result?.data?.myUser;

    if (myUser?.id) {
      // Use our new identifyUser function which handles both setUser and session start
      identifyUser({
        id: myUser.id,
        email: myUser.email,
      });
    }
    // handle all the language settings during login
    await updateUserLanguage(myUser);
    // move xsn's from active folder to offline in case the app crashed or closed improperly
    // and were left in the active folder
    await cleanupActiveSessions();
    // sync any sessions that are already associated with a client but failed to sync
    await autoReconcile();
    // update all meta to indicate sessions are offline
    await updateAllMeta({ offline: true });
    // cleanup meta with no xsn
    await cleanupBadMetaData();

    dispatch(setCurrentUser(myUser));

    dispatch(setIsLoggedIn(true));
    navigate("/auth");
  }, [
    globalSignOut,
    getMyUser,
    dispatch,
    updateUserLanguage,
    cleanupActiveSessions,
    autoReconcile,
    updateAllMeta,
    cleanupBadMetaData,
    navigate,
    handleLoginErrors,
  ]);

  return completeLogin;
}
