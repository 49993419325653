import i18n from "@/configurations/i18n";
import { toaster } from "@/utilities/toaster";
import { error } from "@/utilities/log";
import { NetworkError } from "@apollo/client/errors";

export default function handleNetworkErrors(networkError: NetworkError) {
  const { message } = networkError;

  if (message?.includes("Failed to fetch")) {
    toaster.error(i18n.t("Check your internet connection..."), {
      id: "checkConnection",
    });
  }

  error("Error link (network): ", networkError);
}
