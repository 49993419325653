import { RootContext } from "@/context/rootContext";
import { toaster } from "@/utilities/toaster";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export default function useSubmit(form, clientCreate) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getValues, reset } = form;
  const { clientManyQuery } = useContext(RootContext);
  const [_, { refetch }] = clientManyQuery;
  const submit = useCallback(async () => {
    await clientCreate({
      variables: {
        fullname: getValues("fullname"),
        groups: getValues("groups")?.map((option) => option.value),
        users: getValues("users")?.map((user) => user.id),
        privateID: getValues("privateID"),
      },
    });

    // reset form
    reset();
    // toast success
    toaster.success(t("Client Created!"));

    // navigate from modal
    setTimeout(() => {
      refetch?.();
      navigate(-1, { replace: true });
    }, 200);
  }, [clientCreate, reset, refetch, navigate, getValues, t]);

  return submit;
}
