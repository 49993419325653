import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import.meta.env.PROD &&
  Sentry.init({
    dsn: import.meta.env.VITE_APP_SENTRY_DSN,
    release: "intelligent-insoles@" + import.meta.env.APP_VERSION,
    environment: "Login",
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,
    autoSessionTracking: true,
    tracePropagationTargets: [/^\//, /^https:\/\/.*\.xsensorcloud\.com/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    beforeSend(event) {
      const scope = Sentry.getCurrentScope();
      const session = scope.getSession();

      if (!event.tags) {
        event.tags = {};
      }

      if (session) {
        event.tags.sessionStatus = session.status;
      }

      return event;
    },
  });
