import useOpenDesktopApp from "@/hooks/integration/useOpenDesktopApp/useOpenDesktopApp";
import { DATA_PATH } from "@/utilities/paths/paths";
import { once } from "@tauri-apps/api/event";
import { useCallback } from "react";
import eventListenerBuilder from "@/utilities/events/eventListenerBuilder/eventListenerBuilder";
import useCheckIsSessionLocal from "@/hooks/integration/useCheckIsSessionLocal/useCheckIsSessionLocal";
import useOpenSession from "@/hooks/integration/useOpenSession/useOpenSession";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import useDownloadAndSave from "@/hooks/io/useDownloadAndSave/useDownloadAndSave";
import { join } from "@tauri-apps/api/path";
import { error, info } from "@/utilities/log";

export default function useCompareSessions() {
  const { t } = useTranslation();
  const openDesktopApp = useOpenDesktopApp();
  const [openSession] = useOpenSession();
  const downloadAndSave = useDownloadAndSave();
  const checkIsSessionLocal = useCheckIsSessionLocal();
  const compareSessions = useCallback(
    async (sessions, chainProcess, downloadDesktopSettings = undefined) => {
      try {
        info("[useCompareSessions] started ", sessions);
        let chain = await openDesktopApp(chainProcess, downloadDesktopSettings);
        let openSessions = [];

        if (sessions.length !== 2) {
          info("Must compare exactly two sessions: ", sessions.length);
          return;
        }

        await once(
          sessions[0].sessionID,
          eventListenerBuilder(sessions, openSessions)
        );
        await once(
          sessions[1].sessionID,
          eventListenerBuilder(sessions, openSessions)
        );

        for (const session of sessions) {
          const isSessionLocal = await checkIsSessionLocal(session);

          // if the sessions is not local, download it
          if (!isSessionLocal) {
            const fullPath = await join(
              DATA_PATH.ACTIVE,
              `${session.sessionID}.xsn`
            );
            await downloadAndSave(fullPath);
          }

          await openSession(session, chain);
        }
        info("[useCompareSessions] completed ", sessions);
      } catch (e) {
        toaster.error(t("Failed to compare sessions"));
        error("[useCompareSessions] error ", e);
      }
    },
    [openSession, openDesktopApp, downloadAndSave, checkIsSessionLocal, t]
  );

  return compareSessions;
}
