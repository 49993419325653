import { error } from "@/utilities/log";
import { getCurrentUser, confirmSignUp, signIn } from "aws-amplify/auth";
import { useCallback, useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export default function useVerifyAccount(formValues) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const signUpAws = useCallback(async () => {
    try {
      setLoading(true);

      const currentUser = await getCurrentUser();
      const { isSignUpComplete, userId, nextStep } = await confirmSignUp({
        username: location?.state?.username || currentUser?.user?.username,
        confirmationCode: formValues.verificationCode.trim(),
      });
      await signIn(location?.state?.username, location?.state?.password);

      toaster.success(t("Account verified"));

      navigate("/");
    } catch (e) {
      error(e);
      toaster.error(t("Error verifying account"));
    } finally {
      setLoading(false);
    }
  }, [navigate, formValues, location, t]);

  return { signUpAws, loading };
}
