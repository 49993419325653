import { forwardRef } from "react";
import { InputComponent, TextAreaComponent } from "./inputs";
import { useTranslation } from "react-i18next";

const INPUT = {
  input: InputComponent,
  textarea: TextAreaComponent,
};

const FormInput = forwardRef(
  (
    {
      placeholder,
      errors,
      name,
      className,
      loading,
      label,
      labelClassName = "",
      inputClassName = "",
      type = "text",
      componentType = "input",
      disabled,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const requiredMsg =
      (errors[name]?.required || errors[name]?.type === "required") &&
      `${label} ${t("is required")}`;
    const errorMessage = errors?.[name]?.message || requiredMsg;
    const FormComponent = INPUT[componentType];

    return (
      <div className={`form-control relative flex-col gap-1 ${className}`}>
        {!!label && (
          <label className={`mb-1 font-medium ${labelClassName}`}>
            {label}
          </label>
        )}

        <FormComponent
          {...props}
          ref={ref}
          name={name}
          type={type}
          placeholder={placeholder}
          disabled={loading || disabled}
          className={`focus:border-b-1 input-md bg-base-100 text-base-content 
          caret-base-content placeholder:text-base-content
          focus:border-cyan-600 focus:outline-0 ${inputClassName}`}
        />

        {!!errorMessage && (
          <div className="left-0 text-xs text-red-600">{errorMessage}</div>
        )}
      </div>
    );
  }
);

export default FormInput;
