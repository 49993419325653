import { selectEnvironment } from "@/redux/environment/selectors";
import { store } from "@/configurations/store";
import { Environment } from "@xsensor/intelligent-insoles-types";

export default function getApiUrl(environment?: Environment) {
  const _environment = selectEnvironment(store.getState());
  const { REGION, STAGE } = environment || _environment;
  const useEnvUrl = import.meta.env.MODE === "development" || !REGION || !STAGE;
  const url = useEnvUrl
    ? import.meta.env.VITE_APP_API_URL
    : `https://api.${REGION}.${STAGE}.xsensorcloud.com`;

  return url;
}
