import { store } from "@/configurations/store";
import { selectEnvironment } from "@/redux/environment/selectors";
import { v4 as uuid } from "uuid";
import readable from "uuid-readable";

// TODO: test these in different environments
export default function generateId() {
  const state = store.getState();
  const environment = selectEnvironment(state);
  const id = uuid();
  const clientID =
    environment.STAGE === "prod"
      ? id
      : readable.short(id).trim().replaceAll(" ", "-");

  return clientID;
}
