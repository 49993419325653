import { useCallback } from "react";
import usePlatform from "./usePlatform";
import { getVersion } from "@tauri-apps/api/app";

export default function useGetVersion() {
  const { isWeb } = usePlatform();
  const _getVersion = useCallback(async () => {
    if (isWeb) {
      return import.meta.env.APP_VERSION;
    }

    const version = await getVersion();

    return version;
  }, [isWeb]);

  return _getVersion;
}
