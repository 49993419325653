import { useCallback } from "react";
import { DocumentNode } from "graphql";
import getApiUrl from "@/utilities/getApiUrl";
import { error } from "@/utilities/log";

export default function useGraphqlRest() {
  const fetcher = useCallback(async (query: DocumentNode, variables) => {
    try {
      const apiUrl = getApiUrl();
      const url = apiUrl + "/graphql_rest";
      const body = JSON.stringify({
        query,
        variables,
      });
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      const res = await response?.json();

      return res;
    } catch (e) {
      error(e);
    }
  }, []);

  return fetcher;
}
