import { gql } from "@apollo/client";
import ROLE_FRAGMENT from "../role/roleFragment";

const SETTINGS_FRAGMENT = gql`
  fragment SettingsFragment on DesktopSettings {
    zippedFileName
    updatedAt
    lastUpdatedBy {
      id
      name
      email
      role {
        ...RoleFragment
      }
    }
    settings {
      setting
      file
    }
  }

  ${ROLE_FRAGMENT}
`;

export default SETTINGS_FRAGMENT;
