import { useCallback } from "react";
import { useLazyOfflineMeta } from "./useOfflineMeta";
import { DATA_PATH } from "@/utilities/paths/paths";
import getSessionPaths from "@/utilities/paths/getSessionPaths";
import useRemoveSession from "./useRemoveSession";
import { info } from "@/utilities/log";
import { useTranslation } from "react-i18next";
import useSyncSession from "./useSyncSession";
import { FILE_EXTENSION } from "@/constants";
import { BaseDirectory, readDir } from "@tauri-apps/api/fs";
import { removeFileExtension } from "@/utilities/paths/removeFileExtension";
import toast from "react-hot-toast";

export default function useAutoReconcile() {
  const { t } = useTranslation();
  const [fetchOfflineMeta] = useLazyOfflineMeta();
  const removeSession = useRemoveSession();
  const syncSession = useSyncSession();
  const autoReconcile = useCallback(async () => {
    try {
      info("[useAutoReconcile] started ");
      const offlineMeta = await fetchOfflineMeta();
      const entries = await readDir(DATA_PATH.OFFLINE, {
        dir: BaseDirectory.App,
        recursive: false,
      });
      const fileNames = entries
        .filter((entry) => entry.children !== null) // Filter out directories
        .map((entry) => removeFileExtension(entry.name));

      const syncableMeta =
        offlineMeta?.filter((meta) => {
          return fileNames.includes(meta?.sessionID) && meta.clientID;
        }) || [];

      info("[useAutoReconcile] syncableMeta ", syncableMeta);

      syncableMeta?.length > 0 &&
        toast(t("Syncing previous sessions, please wait..."));

      // sync eligible sessions
      for await (const meta of syncableMeta) {
        const { offlinePath } = await getSessionPaths({
          sessionID: meta?.sessionID,
          extension: meta?.extension || FILE_EXTENSION.XSN,
        });
        await syncSession(offlinePath, meta, false);
        await removeSession(meta?.sessionID, offlinePath);

        await fetchOfflineMeta();
      }

      info("[useAutoReconcile] completed ");
    } catch (e) {
      info("[useAutoReconcile] error: failed to auto reconcile", e);
    }
  }, [syncSession, removeSession, fetchOfflineMeta, t]);

  return autoReconcile;
}
