import { useCallback } from "react";
import getApiUrl from "@/utilities/getApiUrl";
import { Environment } from "@xsensor/intelligent-insoles-types";

export default function useGlobalSignOut() {
  const globalSignOut = useCallback(
    async (email: string, environment: Environment) => {
      const url = getApiUrl(environment);
      await fetch(url + "/user_global_sign_out", {
        method: "POST",
        headers: {
          // "Apollographql-Client-Version": import.meta.env.APP_VERSION,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username: email.trim() }),
      });
    },
    []
  );

  return globalSignOut;
}
