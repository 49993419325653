import { useSelector } from "react-redux";
import { CA, EU, US, AU } from "country-flag-icons/react/1x1";
import { Region } from "@xsensor/shared-types";
import { selectRegion } from "@/redux/environment/selectors";

export default function RegionAvatar() {
  const region = useSelector(selectRegion);
  const Flag: Record<Region, JSX.Element> = {
    [Region.ap_southeast_2]: <AU />,
    [Region.ca_central_1]: <CA />,
    [Region.eu_central_1]: <EU />,
    [Region.us_west_1]: <US />,
  };

  return (
    <div className="avatar">
      <div className="w-10">
        {Flag[region]}
      </div>
    </div>
  );
}
