import { setEnvironment } from "@/redux/environment/slice";
import { useCallback } from "react";
import { info } from "@/utilities/log";
import useGraphqlRest from "../useGraphqlRest";
import CURRENT_ENVIRONMENT from "@/api/environment/currentEnvironment";
import useInitializeAmplify from "../useInitializeAmplify";
import useGetVersion from "../tauri/useGetVersion";
import { useDispatch } from "react-redux";
import { Region, Stage } from "@xsensor/shared-types";
import useGetClientEnvVars from "./useGetClientEnvVars";
import useReadLocalConfig from "../io/useLocalConfigFile/useReadLocalConfig";

export default function useInitializeEnvironment() {
  const dispatch = useDispatch();
  const getVersion = useGetVersion();
  const graphqlRest = useGraphqlRest();
  const initalizeAmplify = useInitializeAmplify();
  const readLocalConfig = useReadLocalConfig();
  const getClientEnvVars = useGetClientEnvVars();
  const initializeEnvironment = useCallback(async () => {
    const config = await readLocalConfig();
    info("[useInitializeEnvironment] config", config);
    const region = config?.region as Region;
    info("[useInitializeEnvironment] region", region);
    const version = await getVersion();
    // this is done as a rest call because apollo client is not yet initialized
    const response = (await graphqlRest(CURRENT_ENVIRONMENT, {
      version,
      region,
    })) as { data?: { currentEnvironment: Stage } };
    const stage = response?.data?.currentEnvironment as Stage;
    info("[useInitializeEnvironment] stage", stage);
    const clientEnvVars = await getClientEnvVars();
    const environment = clientEnvVars[stage][region];
    info("[useInitializeEnvironment] clientEnvVars", clientEnvVars);

    info("[useInitializeEnvironment] environment:", environment);

    dispatch(setEnvironment(environment));

    await initalizeAmplify(environment);

    return environment;
  }, [
    graphqlRest,
    getVersion,
    initalizeAmplify,
    readLocalConfig,
    getClientEnvVars,
    dispatch,
  ]);

  return initializeEnvironment;
}
