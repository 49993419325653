import { selectLocalFiles } from "@/redux/sessions/selectors";
import { setSessionsByPath } from "@/redux/sessions/slice";
import { invoke } from "@tauri-apps/api/tauri";
import { error, info } from "@/utilities/log";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function useGetLocalXsns(path) {
  const localFiles = useSelector(selectLocalFiles);
  const [getSessions, { loading }] = useLazyGetLocalXsns(path);
  const fetch = useCallback(async () => {
    const session = await getSessions();

    return session;
  }, [getSessions]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { data: localFiles?.[path], refetch: fetch, loading };
}

export function useLazyGetLocalXsns(path) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const fetch = useCallback(
    async (_path) => {
      let updatedPath = _path || path;

      info("[useLazyGetLocalXsns] started ", updatedPath);

      try {
        setLoading(true);
        const sessions = await invoke("get_sessions", {
          path: updatedPath,
        });

        dispatch(setSessionsByPath({ path: updatedPath, sessions }));

        info("[useLazyGetLocalXsns] completed ", updatedPath);
        return sessions;
      } catch (e) {
        error("[useLazyGetLocalXsns] error ", e);
      } finally {
        setLoading(false);
      }
    },
    [path, dispatch]
  );

  return [fetch, { loading }];
}
