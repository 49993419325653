import { gql } from "@apollo/client";
import ROLE_FRAGMENT from "../role/roleFragment";
import SETTINGS_FRAGMENT from "../settings/settingsFragment";

const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    language
    name
    email
    subscription
    enabled
    isEmailVerified
    facility
    clients {
      clientID
      fullname
    }
    role {
      ...RoleFragment
    }
    currentRole {
      ...RoleFragment
    }
    desktopSettings {
      ...SettingsFragment
    }
  }

  ${ROLE_FRAGMENT}
  ${SETTINGS_FRAGMENT}
`;

export default USER_FRAGMENT;
