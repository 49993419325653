import { useMemo } from "react";
import CLIENT_CREATE from "@/api/client/clientCreate";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import ClientForm from "../ClientForm";
import MY_USER from "@/api/user/myUser";
import { useTranslation } from "react-i18next";
import useSubmit from "./useSubmit";
import useSelectCurrentFacility from "@/hooks/apolloState/useSelectCurrentFacility";

export default function NewClientForm() {
  const { t } = useTranslation();
  const [getCurrentUser] = useLazyQuery(MY_USER);
  const currentFacility = useSelectCurrentFacility();
  const form = useForm({
    defaultValues: async () => {
      const { data } = await getCurrentUser();
      const currentUser = data?.myUser;

      return {
        users: [
          {
            id: currentUser.id,
            name: currentUser.name,
          },
        ],
      };
    },
  });
  const [clientCreate, { loading: submitting }] = useMutation(CLIENT_CREATE);
  const submit = useSubmit(form, clientCreate);
  const hiddenFields = useMemo(
    () => [currentFacility?.shareAllClients && "users"],
    [currentFacility]
  );

  return (
    <ClientForm
      title={t("Create New Client")}
      submit={submit}
      loading={submitting}
      hiddenFields={hiddenFields}
      form={form}
      buttonLabel={t("Create")}
    />
  );
}
