import { useCallback, useContext } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import USER_DELETE from "@/api/user/userDelete";
import { toaster } from "@/utilities/toaster";
import { error } from "@/utilities/log";
import USER_CREATE from "@/api/user/userCreate";
import { RootContext } from "@/context/rootContext";
import { useDebouncedCallback } from "use-debounce";

export default function useResendInviteOrResetPassword(user) {
  const navigate = useNavigate();
  const [deleteUser, { loading: deleteLoading }] = useMutation(USER_DELETE);
  const [createUser, { loading: createLoading }] = useMutation(USER_CREATE);

  const { userManyQuery } = useContext(RootContext);
  const [_, { refetch }] = userManyQuery;

  const resendInviteOrResetPassword = useCallback(async () => {
    if (user.isEmailVerified) {
      navigate(`send_reset_password_email/${user.id}`);

      return;
    }

    try {
      await deleteUser({
        variables: {
          id: user.id,
        },
      });
      await createUser({
        variables: {
          name: user.name,
          email: user.email,
          role: user.role.name,
          facility: user?.facility,
          clients: user?.clients?.map((client) => client.clientID),
        },
      });

      toaster.success("Temporary password resent");
      refetch();
    } catch (e) {
      error("useResendInviteOrResetPassword", e);
      toaster.error("Failed to resend temporary password");
    }
  }, [
    createUser,
    deleteUser,
    navigate,
    user?.clients,
    user.email,
    user?.facility,
    user.id,
    user.name,
    user.isEmailVerified,
    user.role,
    refetch,
  ]);

  return [
    useDebouncedCallback(resendInviteOrResetPassword, 2000),
    { loading: deleteLoading ?? createLoading },
  ];
}
