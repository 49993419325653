import { confirmResetPassword } from "aws-amplify/auth";
import { useState } from "react";
import { toaster } from "@/utilities/toaster";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { error } from "@/utilities/log";

export default function usePasswordReset() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const resetPassword = async (formData) => {
    try {
      const { email: username, code, password } = formData;
      setLoading(true);
      await confirmResetPassword({
        username,
        newPassword: password,
        confirmationCode: code,
      });
      navigate("/");

      toaster.success(t("Password Was Reset!"));
    } catch (e) {
      toaster.error(t("Password Reset Failed, Please Try Again"));
      error(e.message);
    } finally {
      setLoading(false);
    }
  };

  return { resetPassword, loading };
}
