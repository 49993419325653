import USER_UPDATE from "@/api/user/userUpdate";
import USER_RESET_PASSWORD from "@/api/user/userUpdatePassword";
import useSelectUser from "@/hooks/apolloState/useSelectUser";
import { useMutation } from "@apollo/client";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RootContext } from "@/context/rootContext";
import { toaster } from "@/utilities/toaster";
import { error } from "@/utilities/log";

export default function useSubmit(getValues, userID) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userManyQuery } = useContext(RootContext);
  const [_, { refetch }] = userManyQuery;
  const [loading, setLoading] = useState(false);
  const user = useSelectUser(userID);
  const [userUpdate] = useMutation(USER_UPDATE);
  const [userResetPassword] = useMutation(USER_RESET_PASSWORD);
  const editUser = useCallback(async () => {
    setLoading(true);
    const password = getValues("password");

    try {
      password &&
        (await userResetPassword({
          variables: {
            email: getValues("email"),
            password,
          },
        }));

      await userUpdate({
        variables: {
          id: user.id,
          email: getValues("email"),
          name: getValues("name"),
          facility: getValues("facility").id,
          role: getValues("role").value,
          clients: getValues("clients")?.map((client) => client.clientID),
        },
      });

      toaster.success(`${user?.name} ${t("was updated!")}`);

      navigate("../");
      refetch();
    } catch (e) {
      error(e);
      // toaster.error(`${t("Failed to update")} ${user?.name}!`);
    } finally {
      setLoading(false);
    }
  }, [userUpdate, getValues, user, userResetPassword, navigate, refetch, t]);

  return [editUser, { loading }];
}
