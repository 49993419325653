import { useMemo } from "react";
import ROLE_MANY from "@/api/role/roleMany";
import { useQuery } from "@apollo/client";

import SuperSelect from "../SuperSelect/SuperSelect";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { emptyArr } from "@/utilities/empties";

export default function SelectRole({ control, rules, hideRoles=emptyArr, ...props }) {
  const { t } = useTranslation();
  const { loading, data } = useQuery(ROLE_MANY);
  const roles = useMemo(() => data?.roleMany?.filter(({ name }) => !hideRoles.includes(name)), [data]);

  return (
    <Controller
      control={control}
      name="role"
      rules={rules}
      render={({ field }) => {
        return (
          <SuperSelect
            placeholder={t("Role")}
            options={roles?.map(({ name }) => ({
              value: name,
              label: t(name),
            }))}
            isLoading={loading}
            isDisabled={loading}
            {...field}
            {...props}
          />
        );
      }}
    />
  );
}
