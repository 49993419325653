import { createSlice } from "@reduxjs/toolkit";
import purge, { PURGE } from "../purge";

const initialState = {
  isOnline: true,
  isLoggedIn: false,
  currentUser: undefined,
  manuallySelectedLanguage: undefined,
  desktopVersion: undefined,
  desktopBuildDate: undefined,
  unverifiedUser: undefined,
};

export const accountSlice = createSlice({
  name: "ACCOUNT",
  initialState,
  reducers: {
    setUnverifiedUser: (state, { payload }) => {
      state.unverifiedUser = payload;
    },
    setIsOnline: (state, { payload }) => {
      state.isOnline = payload;
    },
    setCurrentUser: (state, { payload }) => {
      state.currentUser = payload;
    },
    setManuallySelectedLanguage: (state, { payload }) => {
      state.manuallySelectedLanguage = payload;
    },
    setIsLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setDesktopVersion: (state, { payload }) => {
      state.desktopVersion = payload;
    },
    setDesktopBuildDate: (state, { payload }) => {
      state.desktopBuildDate = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, purge(initialState));
  },
});

export const {
  setUnverifiedUser,
  setIsOnline,
  setCurrentUser,
  setManuallySelectedLanguage,
  setIsLoggedIn,
  setDesktopVersion,
  setDesktopBuildDate,
} = accountSlice.actions;

export default accountSlice;
